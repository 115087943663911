import { StateCreator } from 'zustand';

export interface BrowserSlice {
	isMobile: boolean;
	setIsMobile: (isMobile: boolean) => void;
}

export const createBrowserSlice: StateCreator<BrowserSlice> = (set) => ({
	isMobile: false,
	setIsMobile: (isMobile) => set(() => ({ isMobile: isMobile })),
});
