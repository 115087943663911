import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { BrowserSlice, createBrowserSlice } from 'slices/userSlice';

// Do not store any sensitive information. Zustand uses localStorage which is saved in the browser.
export const useCentralStore = create<BrowserSlice>()(
	persist(
		(...a) => ({
			...createBrowserSlice(...a),
		}),
		{ name: 'central-store' },
	),
);
